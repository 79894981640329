$platform-name: 'kingswaycommunitycare';


$brand-primary: #0085CA;
$brand-secondary: #AEB8C0;
$brand-tertiary: #F1206A;
$donate-colour: $brand-tertiary;
$black: #2D2926;

// layout
$container-max-width: 1230px;
$gap-width: 40px;
$site-bleed: 20px;
$border-radius: 0;

// Spacers
$spacer: 1rem;
$spacer-y: $spacer;
$spacer-x: $spacer;

// Logo
$logo: 'logo_reverse.png';
$logo-width: 175px;
$logo-height: 107px;
$logo-absolute: true;

// Header
$header-absolute: true;
$header-absolute-background: linear-gradient(to bottom, rgba($black, .8), rgba($black, 0));
// $header-search-enabled: false;
$social-icons-background-colour: transparent;
$social-icons-colour: $brand-secondary;
$social-icons-hover-colour: $brand-secondary;
$header-content-padding-top: 30px;
$header-content-padding-bottom: 0px;
$header-content-margin-top: 0;
$header-content-margin-bottom: 0;
// $header-background-colour: darken($brand-primary, 10%);
$header-background-colour: $black;

// No banner - need some control over admin menu link colour
$no-banner-header-background-colour: #fff;
$no-banner-logo: 'logo.png';
$no-banner-nav-top-level-item-colour: $brand-primary;
$no-banner-social-icons-header-colour: $brand-secondary;
$no-banner-social-icons-header-hover-colour: $brand-primary; // Not working
$no-banner-header-search-input-placeholder-colour: $black;


// Menu admin
$menu-admin-login-link-enabled: true;
$menu-admin-absolute: true;
$menu-admin-background-colour: transparent;
$menu-admin-link-colour: #fff;

// Search
$header-search-margin-right: 0;
$header-search-input-margin-right: -40px;
$header-search-input-background-colour: rgba(45, 41, 38, 0.25);
$header-search-input-placeholder-colour: $brand-secondary;
$header-search-input-border-colour: transparent;
$header-search-button-icon-colour: $brand-primary;
$header-search-input-border-width: 1px;
$header-search-button-width: 30px;
$header-search-button-height: 47px;
$header-search-button-background-colour: transparent;
$header-search-input-border-radius: 3px;
$header-search-input-padding: 12px 10px;

// Buttons
$btn-padding-x: 1.5em;
$btn-padding-y: 0.75em;
$btn-hover-style: lighten;
$donate-btn-colour: #fff;
$btn-text-colour: #fff;
$btn-border-radius: 3px;

// Typography
// $font-family-base: 'ltc-caslon-pro', serif;
$font-family-base: 'Merriweather', serif;
// $headings-font-family: 'aktiv-grotesk', sans-serif;
$headings-font-family: 'Roboto', sans-serif;
$headings-font-weight: 700;
$headings-letter-spacing: -0.02rem;

// Navigation
$nav-background-colour: transparent;
$nav-top-level-item-colour: #fff;
$nav-font-family: $headings-font-family;
$nav-top-level-item-font-weight: 700;
$nav-normal-align-items: right;

$nav-submenu-background-colour: rgba(230, 234, 238, 0.95);
$nav-submenu-width: 250px;

// $burger-btn-background-colour: transparent;
// $burger-btn-text-colour: $brand-secondary;

// Carousel
$carousel-max-width: 100%;
$carousel-contents-max-width: $container-max-width;
$carousel-image-overlay: rgba(#000, .5);
$carousel-details-background-colour: transparent;
$carousel-details-position-x: center;
$carousel-details-position-y: center;
$carousel-details-text-align: center;
$carousel-details-max-width: 800px;

$carousel-heading-colour: #fff;
$carousel-heading-font-size: 2.5em;

$carousel-summary-colour: #fff;
$carousel-summary-font-size: 1.25em;

$carousel-read-more-enabled: false;

$carousel-controls-position-y: center;
$carousel-controls-position-x: split;
$carousel-controls-margin-x: 20px;
$carousel-controls-button-background-colour: transparent;
$carousel-controls-icon-font-size: 2em;

// Carousel after breakpoint
$carousel-mobile-details-background-colour: darken($brand-primary, 10%);

// Home Intro
$home-intro-background-colour: transparent;
$home-intro-padding-y: $spacer;
$home-intro-content-max-width: 900px;

// Cards
//$card-border: none;
$card-hover-details-background-colour: #E6EAEE;
$card-hover-box-shadow: 0px 0px 10px #ddd;

// Home Features
$home-features-padding-top: $spacer * 3;
$home-features-padding-bottom: $spacer * 5;
$home-features-cards-entry-animation-prefab: 1;
$home-features-cards-entry-animation-stagger: true;
$home-features-cards-entry-animation-stagger-count: 3;


// Home Quick Giving
$home-quick-giving-type: simple;
$home-quick-giving-background-colour: $brand-primary;
$home-quick-giving-heading-colour: #fff;
$home-quick-giving-donation-amount-background-colour: #066EA4;
$home-quick-giving-donate-btn-background-colour: $brand-tertiary;
$home-quick-giving-donation-amount-selected-background-colour: $brand-tertiary;


// Impact stats
$impact-stats-padding-y: $spacer * 5;
$impact-stats-figure-colour: $brand-secondary;
$impact-stats-background-colour: $black;
$impact-stats-background-image: "statsbg.jpg";
$impact-stats-background-image-opacity: 0.6;

// Home feeds
$home-feeds-background-colour: #fff;
$home-feeds-padding-top: $spacer * 5;
$home-feeds-padding-bottom: $spacer * 2;
$home-feeds-cards-entry-animation-prefab: 1;
$home-feeds-cards-entry-animation-stagger: true;
$home-feeds-cards-entry-animation-stagger-count: 3;

// Sidebars - off
$sidebar-enabled: false;
$post-content-max-width: 900px;
$sidebar-layout: below;

// Pages
$blockquote-background-colour: #ECEDF0;
$blockquote-colour: #fff;
$blockquote-text-align: left;
$blockquote-padding: $spacer * 2;
$blockquote-font-family: $font-family-base;
$blockquote-font-size: 1.2em;
$blockquote-colour: $black;

// Footer
$footer-background-colour: darken($brand-primary, 10%);
$footer-link-colour: #fff;
$social-icons-footer-colour: #fff;
$footer-padding-y: $spacer * 5;
$footer-boxes-count: 4;
$footer-prefab: 3;

// Newsletter
$newsletter-background-colour: transparent;
$newsletter-colour: #fff;
$newsletter-heading-text-align: left;
$newsletter-button-height: 40px;

// Donation amounts
$donation-amount-min-width: 230px;


// Subsites
$subsite-enabled: true;
$subsite-nav-background-colour: $brand-secondary;
$subsite-logo: title;
$subsite-nav-margin-bottom: 0;
